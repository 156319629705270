import React, { useState, useContext } from "react";
import { graphql } from "gatsby";
import moment from "moment";
import QRCode from "qrcode.react";
import { Helmet } from "react-helmet";

import { Button } from "gatsby-theme-material-ui";

import CardContent from "@material-ui/core/CardContent";
import Container from "../components/Container";
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import GetAppIcon from "@material-ui/icons/GetApp";
import ShareIcon from "@material-ui/icons/Share";

import Layout from "../components/layout";
import BackButton from "../components/BackButton";

import useUrl from "../hooks/useUrl";
import useLanguage from "../hooks/useLanguage";

import { convertSlashDateToMoment, formatMomentDateByLang } from "../utils";
import i18nContext from "../i18n-context";

import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const ContentPage = ({ data, pageContext }) => {
  const url = useUrl();
  const lang = useLanguage();
  const classes = useStyles();
  const i18n = useContext(i18nContext)[lang];
  const [dialogOpen, setDialogOpen] = useState(false);
  const [shareOptionsAnchorEl, setShareOptionsAnchorEl] = useState(null);
  const [downloadOptionsAnchorEl, setDownloadOptionsAnchorEl] = useState(null);

  const {
    title,
    content,
    translated,
    pdfFiles,
    researchCategories,
    contentGenericGroup: {
      author,
      synopsis,
      thumbnail,
      additionalTags,
      publicationDate,
      contentDetailPageBanner,
    },
  } = pageContext;
  const thumbnailUrl = thumbnail?.sourceUrl;
  const bannerUrl = contentDetailPageBanner?.sourceUrl;
  const researchCategoryName = researchCategories?.nodes[0]?.name;
  const researchCategoryUri = researchCategories?.nodes[0]?.uri;
  const eventDate = pageContext?.eventFieldGroup?.eventStartDate.split(" ")[0];

  const date = formatMomentDateByLang(
    url.includes("/event")
      ? moment(eventDate, "DD/MM/YYYY")
      : convertSlashDateToMoment(publicationDate),
    lang
  );

  const tags = additionalTags?.split(", ");
  const urlLang = lang === "tc" ? "/zh-hant" : lang === "sc" ? "/zh-hans" : ""
  const { publicationPage, eventPage, mediaPage, speechPage } = data;

  let backButtonUrl = researchCategoryUri || publicationPage.uri;
  let backButtonName = researchCategoryName
    ? researchCategoryName
    : publicationPage.title;
  let downloadButtonName = i18n.contentPage.downloadFullReport;

  if (url.includes("/event/")) {
    backButtonUrl = eventPage.uri;
    backButtonName = eventPage.title;
    downloadButtonName = i18n.contentPage.downloadPdf;
  }
  if (url.includes("/press-releases/")) {
    backButtonUrl = mediaPage.uri;
    backButtonName = mediaPage.title;
    downloadButtonName = i18n.contentPage.downloadPdf;
  }
  if (url.includes("/speech/")) {
    backButtonUrl = speechPage.uri;
    backButtonName = speechPage.title;
  }

  const info = [date, author].filter((ele) => ele).join(" | ");

  const shareButtonsConfig = [
    {
      href: `https://service.weibo.com/share/share.php?url=${url}&title=${title}`,
      src: "/weibo.png",
      alt: "share-button-weibo",
    },
    {
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
      src: "/linkedin.png",
      alt: "share-button-linkedin",
    },
    {
      href: `https://twitter.com/share?text=${title}&url=${url}`,
      src: "/twitter.png",
      alt: "share-button-twitter",
    },
    {
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      src: "/facebook.png",
      alt: "share-button-facebook",
    },
    {
      src: "/wechat.png",
      alt: "share-button-wechat",
      onClick: () => setDialogOpen(true),
    },
  ];

  const toggleDownloadOptions = (event) => {
    setDownloadOptionsAnchorEl(event.currentTarget);
  };

  const handleDownloadOptionsClose = () => {
    setDownloadOptionsAnchorEl(null);
    setDialogOpen(false);
  };

  const toggleShareOptions = (event) => {
    setShareOptionsAnchorEl(event.currentTarget);
  };

  const handleShareOptionsClose = () => {
    setShareOptionsAnchorEl(null);
  };

  const handleFileDownload = (link) => {
    window.gtag('event', link);
  };

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${title}`}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={synopsis} />
        <meta property="og:image" content={thumbnailUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta property="og:type" content="article" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={synopsis} />
        <meta name="twitter:image" content={thumbnailUrl} />
      </Helmet>

      <Container className={classes.container}>
        <div className={classes.headingSection} aria-label="content-headings">
          <Typography variant="body2" className={classes.headingCategory}>
            {url.includes("/event/") && eventPage.name}
            {url.includes("/press-release/") && mediaPage.name}
            {(url.includes("/report/") || url.includes("/periodical/")) &&
              (researchCategoryName || "")}
          </Typography>
          <Typography variant="h4" paragraph>
            {title}
          </Typography>
          <Typography variant="body2" paragraph>
            {info}
          </Typography>
        </div>
        <Grid className={classes.overContainer} container justifyContent="center">
          <img
            src={bannerUrl || thumbnailUrl || ""}
            alt=""
            className={classes.thumbnail}
          />
        </Grid>
        <Typography
          paragraph
          variant="body2"
          component="div"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <Divider className={classes.divider} />

        <Grid container spacing={2} className={classes.actionButtons}>
          {pdfFiles?.length === 1 && (
            <Grid item>
              <Button
                startIcon={<GetAppIcon />}
                href={pdfFiles[0]}
                target="_blank"
                onClick={() => handleFileDownload(pdfFiles[0])}
              >
                {downloadButtonName}
              </Button>
            </Grid>
          )}
          {pdfFiles?.length > 1 && (
            <Grid item>
              <Button
                startIcon={<GetAppIcon />}
                onClick={toggleDownloadOptions}
              >
                {i18n.contentPage.downloadPdf}
              </Button>
              <StyledMenu
                anchorEl={downloadOptionsAnchorEl}
                open={Boolean(downloadOptionsAnchorEl)}
                onClose={handleDownloadOptionsClose}
              >
                {pdfFiles.map((link) => (
                  <MenuItem
                    key={link}
                    component="a"
                    href={link}
                    target="_blank"
                    onClick={() => handleFileDownload(link)}
                  >
                    <Typography variant="body2">
                      {link.split("/")[link.split("/").length - 1]}
                    </Typography>
                  </MenuItem>
                ))}
              </StyledMenu>
            </Grid>
          )}
          <Grid item>
            <Button startIcon={<ShareIcon />} onClick={toggleShareOptions}>
              {i18n.contentPage.share}
            </Button>
            <Popover
              anchorEl={shareOptionsAnchorEl}
              open={Boolean(shareOptionsAnchorEl)}
              onClose={handleShareOptionsClose}
              elevation={0}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Paper variant="outlined" elevation={0} square>
                <CardContent className={classes.cardContent}>
                  <Typography variant="body2">
                    {i18n.contentPage.selectSharingChannel}
                  </Typography>
                  <Grid container spacing={1}>
                    {shareButtonsConfig.map((button) => (
                      <Grid item key={button.alt}>
                        <IconButton
                          href={button.href}
                          onClick={button.onClick}
                          target="_blank"
                        >
                          <img
                            src={button.src}
                            alt={button.alt}
                            style={{
                              objectFit: "fill",
                              width: "32px",
                              height: "auto",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Paper>
            </Popover>
          </Grid>
        </Grid>

        {Array.isArray(tags) && tags.length > 0 && (
          <div>
            <Typography variant="body2" className={classes.articleTagsHeading}>
              {i18n.contentPage.articleTags}
            </Typography>
            <Grid container spacing={1}>
              {tags.map((tag) => (
                <Grid item key={`${title}-tag-${tag}`}>
                  <Button
                    variant="contained"
                    disableElevation
                    className={classes.articleTag}
                    to={`${urlLang}/search?search=${encodeURIComponent(tag)}`}
                  >
                    {tag}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Divider className={classes.divider} />
          </div>
        )}

        <BackButton
          to={backButtonUrl}
        >{`${i18n.contentPage.backTo}${backButtonName}`}</BackButton>
      </Container>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <QRCode value={url} />
          </Grid>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    borderRadius: 0,
  },
}))((props) => (
  <Menu
    variant="menu"
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    transformOrigin={{ vertical: "top", horizontal: "left" }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    marginBottom: theme.spacing(4),
  },
  headingSection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  headingCategory: {
    color: theme.palette.error.main,
  },
  thumbnail: {
    marginBottom: theme.spacing(4),
    maxWidth: "100%",
    objectFit: "contain",

    [theme.breakpoints.down("xs")]: {
      // maxWidth: `calc(100% + 32px)`,
      width: "100%",
      height: "100%",
    },
  },

  overContainer: {
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% + 32px)`,
      marginLeft: "-16px",
    },
  },

  divider: {
    backgroundColor: theme.palette.text.primary,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(4)}px`,
    },
  },
  actionButtons: {
    marginBottom: theme.spacing(2),
  },
  articleTagsHeading: {
    marginBottom: theme.spacing(2),
  },
  articleTag: {
    borderRadius: 0,
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
  backButtonContainer: {
    marginBottom: theme.spacing(4),
  },
  backButton: {
    fontWeight: 400,
  },
  cardContent: {
    paddingBottom: `16px !important`,
  },
}));

export const query = graphql`
  query getPostQuery($locale: String) {
    publicationPage: wpPage(
      pageType: { pageType: { eq: "2" } }
      locale: { locale: { eq: $locale } }
    ) {
      uri
      title
    }
    eventPage: wpPage(
      pageType: { pageType: { eq: "3" } }
      locale: { locale: { eq: $locale } }
    ) {
      uri
      title
    }
    mediaPage: wpPage(
      pageType: { pageType: { eq: "4" } }
      locale: { locale: { eq: $locale } }
    ) {
      uri
      title
    }
    speechPage: wpPage(
      pageType: { pageType: { eq: "10" } }
      locale: { locale: { eq: $locale } }
    ) {
      id
      uri
      title
      locale {
        locale
      }
    }
  }
`;

export default ContentPage;
